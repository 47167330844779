export default {
    maxLimit: 3,
    backgroundColor: "transparent",
    lineHeight: "auto",
    showButton: false,
    alwaysShowProgressbar: true,
    showArrow: false,
    tabs: [
        {
            productType: "work-management",
            title: {
                title: "Work Management",
                body: "Manage projects & tasks",
                productImage: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-031023/wm-dark.png",
                lineHeight: "100%"
            },
            tabContent: {}
        },
        {
            productType: "crm",
            title: {
                title: "CRM",
                body: "Streamline sales cycle",
                productImage: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-031023/crm-dark.png"
            },
            tabContent: {}
        },
        {
            productType: "dev",
            title: {
                title: "Dev",
                body: "Deliver better products",
                productImage: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-031023/dev-dark.png"
            },
            tabContent: {}
        }
    ],
    timerEnabled: false,
    tabsTitle: {
        titleText: "Find the right product for your team",
        titleMarginBottom: "20px"
    },
    theme: "background-gray"
};
