import { WORK_OS_IRIS_COLOR_NAME, DEV_PRIMARY_COLOR_NAME, CRM_PRIMARY_COLOR_NAME, DEV_DARKER_TINT_02_COLOR_NAME, CRM_DARKER_TINT_02_COLOR_NAME } from "styles/color-consts";
import { CRM_PRODUCT_ID, DEV_PRODUCT_ID, WORK_MANAGEMENT_PRODUCT_ID } from "constants/products";
export const buttonConfigByProduct = (isV2, excludeFromWMHighIntentExperienceTest)=>{
    return {
        "work-management": {
            buttonColor: WORK_OS_IRIS_COLOR_NAME,
            signupProductId: WORK_MANAGEMENT_PRODUCT_ID,
            excludeFromWMHighIntentExperienceTest
        },
        crm: {
            buttonColor: isV2 ? CRM_DARKER_TINT_02_COLOR_NAME : CRM_PRIMARY_COLOR_NAME,
            signupProductId: CRM_PRODUCT_ID
        },
        dev: {
            buttonColor: isV2 ? DEV_DARKER_TINT_02_COLOR_NAME : DEV_PRIMARY_COLOR_NAME,
            signupProductId: DEV_PRODUCT_ID
        }
    };
};
export const additionalText = {
    "work-management": "signup.button.unlimitedTimeCta",
    crm: "signup.button.CRMUnlimitedTimeCta",
    dev: "signup.button.DevUnlimitedTimeCta"
};
