import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { UI_BORDER_LIGHT_COLOR_NAME } from "styles/color-consts";
import Colors from "/styles/colors";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH } from "/constants/vp-sizes";
export const StyledTagsByProductHomepageFirstFoldComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
    .paragraph-wrapper .full-paragraph .title-wrapper .monday-markup-language-component {
      font-size: 4rem;
      line-height: 72px;
      font-weight: 700;
    }
  }
  .product-tabs-wrapper {
    .products-horizontal-timer-tabs-component
      .timer-vertical-tabs-desktop-component
      .vertical-timer-tabs-container
      .desktop-tabs-text-container {
      border-bottom: 1px solid;
      border-color: ${Colors[UI_BORDER_LIGHT_COLOR_NAME]};
      .tab {
        width: unset;
        .progressbar {
          background-color: unset;
        }
      }
    }
    .square-tags .clusters-stars {
      margin: 32px 0 40px 0;
      @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
        margin: 16px 0 20px 0;
      }
    }
  }
`;
